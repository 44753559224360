import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'SearchAsset',
  components: {
    appStrings,
    DatePicker
  },
  data() {
    return {
      unsubscribe: null,
      showAddTransferAssetModal: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      batchNo: null,
      currentPage: 1,
      totalRows: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      pvHrdId: 0,
      legalEntity: {
        text: null,
        value: null
      },
      bookType: {
        text: null,
        value: null
      },
      location: {
        text: null,
        value: null
      },
      employee: {
        text: null,
        value: null
      },
      defaultValue: {
        text: null,
        value: null
      }
    };
  },
  validations: {
    location: {
      text: {
        required
      }
    },
    bookType: {
      text: {
        required
      }
    },
    legalEntity: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditCreatePvJob();
        }
      }
    });
  },
  methods: {
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_BOOK_TYPE) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.bookType = this.defaultValue;
          break;
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.bookType = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.LOCATION:
          this.location = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };

          break;
        case appStrings.VALUESETTYPE.Select_Employee:
          this.employee = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addEditCreatePvJob() {
      const payload = {
        book_type_code: this.bookType.value,
        employee_id: this.employee.value,
        le_id: this.legalEntity.value,
        location_id: this.location.value,
        pv_hdr_id: this.pvHrdId
      };
      this.loader = true;
      this.$store
        .dispatch('assets/addEditCreatePvJob', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.batchNo = response.data.data.doc_num;
            this.pvHrdId = response.data.data.pv_hdr_id;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      } else if (vsetCode === this.bookType.value) {
        this.bookType = this.defaultValue;
      } else if (vsetCode === this.location.value) {
        this.location = this.defaultValue;
      } else if (vsetCode === this.employee.value) {
        this.employee = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
