import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import createBatchDetails from './createBatchDetails';
import addEditCreatePvJob from './addEditCreatePvJob';
import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'SearchAsset',
  components: {
    createBatchDetails,
    DatePicker,
    addEditCreatePvJob
  },
  watch: {
    currentPage: function() {
      this.getCreateCvJobBatch();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCreateCvJobBatch();
    }
  },
  data() {
    return {
      setTimeVsetCode: null,
      vsetCode: null,
      showCreateBatchDetailsModal: false,
      showAddCreatePvJobModal: false,
      creationDate: [],
      loader: false,
      batchNo: null,
      description: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      legalEntity: {
        text: null,
        value: null
      },
      bookType: {
        text: null,
        value: null
      },
      currentLocation: {
        text: null,
        value: null
      },
      batchStatus: {
        text: null,
        value: null
      },
      employee: {
        text: null,
        value: null
      },
      receiveAssetData: [],
      receiveAssetFields: [
        {
          key: 'doc_num',
          label: 'Batch No.'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'book_type_code'
        },
        {
          key: 'location_name'
        },
        {
          key: 'employee_name',
          label: 'Employee'
        },
        {
          key: 'batch_status_meaning',
          label: 'Status'
        },
        {
          key: 'batch_date'
        }
      ],
      payload: null,
      batchDetail: null,
      defaultValue: {
        text: null,
        value: null
      }
    };
  },
  validations: {
    bookType: {
      text: {
        required
      }
    },
    legalEntity: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add' && !this.showCreateBatchDetailsModal) {
          this.showHideCreatePvJobModal(true)
        }
      }
    });
  },
  methods: {
    getCreateCvJobBatch() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.legalEntity.value,
        book_type_code: this.bookType.value,
        employee_id: this.employee.value,
        loaction_id: this.currentLocation.value,
        batch_status: this.batchStatus.value,
        batch_num: this.batchNo,
        batch_date_from: commonHelper.formattedDate(this.creationDate[0]),
        batch_date_to: commonHelper.formattedDate(this.creationDate[1])
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getCreatePvJobBatchList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.receiveAssetData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => (this.loader = false));
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_BOOK_TYPE) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.bookType = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.bookType = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.LOCATION:
          this.currentLocation = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_PV_STATUS:
          this.batchStatus = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
        case appStrings.VALUESETTYPE.Select_Employee:
          this.employee = {
            value: item.employee_num,
            text: item.employee_name
          };
          break;
      }
    },
    // chooseValueset(name) {
    //   switch (name) {
    //     case 'legal_entity':
    //       this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
    //       break;
    //     case 'book_type':
    //       this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_BOOK_TYPE);
    //       break;
    //     case 'currentLocation':
    //       this.openValueSetModal(appStrings.VALUESETTYPE.LOCATION);
    //       break;
    //     case 'batch_status':
    //       this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_PV_STATUS);
    //       break;
    //     case 'Select_Employee':
    //       this.openValueSetModal(appStrings.VALUESETTYPE.Select_Employee);
    //       break;
    //   }
    // },
    // chooseParentVsetId(name) {
    //   switch (name) {
    //     case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
    //       this.parent_value_set_id = this.legalEntity.value;
    //       break;
    //     default:
    //       this.parent_value_set_id = null;
    //   }
    // },

    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    mainSearch() {
      this.getCreateCvJobBatch();
    },
    clear() {
      this.legalEntity = this.defaultValue;
      this.bookType = this.defaultValue;
      this.currentLocation = this.defaultValue;
      this.batchStatus = this.defaultValue;
      this.toLocation = this.defaultValue;
      this.employee = this.defaultValue;
      this.batchNo = null;
      this.description = null;
      this.creationDate = [];
      this.receiveAssetData = [];
      this.currentPage = 1;
      this.totalRows = null;
    },
    showHideCreatePvJobModal(flag) {
      this.showAddCreatePvJobModal = flag;
      if (!flag) {
        this.getCreateCvJobBatch();
      }
    },
    showHideCreateBatchDetailsModal(flag) {
      this.showCreateBatchDetailsModal = flag;
      if (!flag) {
        this.getCreateCvJobBatch();
      }
    },
    // searchCreatedBatch(batch_no, legal_Entity, book_type) {
    //   this.batchNo = batch_no;
    //   this.legalEntity = legal_Entity;
    //   this.bookType = book_type;
    //   this.getTransferAssetsList();
    // },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      } else if (vsetCode === this.bookType.value) {
        this.bookType = this.defaultValue;
      } else if (vsetCode === this.currentLocation.value) {
        this.currentLocation = this.defaultValue;
      } else if (vsetCode === this.batchStatus.value) {
        this.batchStatus = this.defaultValue;
      } else if (vsetCode === this.employee.value) {
        this.employee = this.defaultValue;
      }
    },
    rowSelected(item) {
      this.batchDetail = item;
      this.showHideCreateBatchDetailsModal(true);
    }
  }
};
